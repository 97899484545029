/** @jsx jsx */
import { jsx, Flex, Container } from "theme-ui"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, gql } from "@apollo/client"
import { navigate } from "gatsby"
import config from "../../../config"
import slashes from "remove-trailing-slash"
import { createEmailInput, removeMarks, escapeSpecialChars } from "../../utils"

import { window } from "browser-monads"
import flatten from "lodash/flatten"

const url = slashes(config.siteUrl)

const RESULT_MUTATION = gql`
  mutation($input: ResultMutationInput!) {
    resultMutation(input: $input) {
      resultSubmitted
      clientMutationId
    }
  }
`

const SEND_EMAIL = gql`
  mutation($input: SendEmailInput!) {
    sendEmail(input: $input) {
      origin
      sent
      message
    }
  }
`

export const SubmitForm = ({
  detectedDragonsData,
  localAnswers,
  allDragons,
}) => {
  let dragonsHeaders = allDragons?.map(item =>
    item.title.replace(",", "").trim()
  )

  let answers = localAnswers?.map(answer => answer.answers)
  const trueAnswers =
    answers &&
    flatten(
      flatten(answers.map(item => Object.entries(item))).filter(
        item => item[1] === "true"
      )
    )
      .filter(item => item !== "true")
      .map(item => removeMarks(escapeSpecialChars(item)))

  answers = flatten(answers?.map(item => Object.entries(item))).map(item => {
    let [question, answer] = item
    question = removeMarks(escapeSpecialChars(question))
    answer = answer === "true" ? 1 : 0

    return [question, answer]
  })
  answers = answers.reduce((acc, item) => {
    acc[removeMarks(item[0])] = item[1]
    return acc
  }, {})

  console.log("answers", answers)

  const createAnswerSheetRow = mailData => {
    const { email } = mailData

    let body = ((answers.email = email), answers)

    fetch("/api/writeAnswers", {
      method: "POST",
      body: JSON.stringify(body),
    })
    console.log("answers row created", body)
  }

  const createDragonsSheetRow = (mailData, resultId) => {
    const { email, firstName } = mailData
    const detectedDragonsTitles = detectedDragonsData?.map(item =>
      item.title.replace(",", "").trim()
    )

    const dragonsResults = dragonsHeaders.reduce((acc, item) => {
      let answer = detectedDragonsTitles.includes(item) ? 1 : 0
      acc[item] = answer

      return acc
    }, {})

    const now = new Date()
    const convertDateToPST = date => {
      const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
      const pst = new Date(utc.getTime() - 7 * 60 * 60 * 1000)
      return pst
    }
    const nowInPST = convertDateToPST(now)
    console.log("Now in PST is: ", nowInPST)
    let body = {
      Email: email,
      Name: firstName,
      Results: `${url}/results/${resultId}`,
      Timestamp: nowInPST,
      Raw: `
        Email: ${email}, Name: ${firstName},  Results: ${url}/results/${resultId},detectedDragons: ${detectedDragonsTitles.join(
        ","
      )},
      `,
      ...dragonsResults,
    }

    fetch("/api/writeAnswers", {
      method: "POST",
      body: JSON.stringify(body),
    })
    console.log("dragons row created", body)
  }

  const resultsIds =
    detectedDragonsData && detectedDragonsData.map(dragon => dragon.databaseId)

  const resultsTitlesArray = detectedDragonsData?.map(dragon => {
    return dragon.title.replace(",", " ")
  })

  const resultsTitles = resultsTitlesArray?.join(", ")

  const [resultId, setResultId] = useState(null)
  const [resultErrors, setResultErrors] = useState(null)
  const [mailData, setMailData] = useState(null)

  const handleError = err => {
    console.log(`oh noooo something went wrong!!!! 💩`)
    console.log(err)
    setResultErrors(err)
  }

  const id = Date.now().toString()

  const [resultMutation] = useMutation(RESULT_MUTATION)
  const [sendEmail] = useMutation(SEND_EMAIL)

  useEffect(() => {
    if (!resultErrors && resultId) {
      sendEmail({
        variables: {
          input: createEmailInput(
            mailData,
            resultsTitlesArray,
            url,
            id,
            resultId
          ),
        },
      })
      navigate(`${resultId}`, { replace: true })
    }
  }, [resultId])

  const createResultsInput = (mailData, resultId) => {
    const { email, firstName } = mailData

    const results = `
    ${firstName},
    ${email},
    ${url}/results/${resultId},
    ${resultsTitles}
    `

    return {
      clientMutationId: id,
      emailInput: email,
      slugInput: id,
      firstNameInput: firstName,
      dragonsInput: resultsIds,
      resultsInput: results,
      answersInput: trueAnswers.join(" / "),
    }
  }

  const { register, handleSubmit, errors, reset } = useForm()

  const onSubmit = async mailData => {
    try {
      setResultErrors(null)
      setMailData(mailData)

      const { data: resultData } = await resultMutation({
        variables: {
          input: createResultsInput(mailData, id),
        },
      })
      setResultId(resultData.resultMutation.clientMutationId)
      if (window.fbq != null) {
        window.fbq("track", "Lead")
      }
      createAnswerSheetRow(mailData)
      createDragonsSheetRow(mailData, id)

      reset()
    } catch (error) {
      console.log(">>> resultErrors", error)
      handleError(error)
    }
  }

  return (
    <>
      <Container sx={{ maxWidth: "l", mb: 40 }}>
        <Flex
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          NOW IT’S TIME TO LEARN HOW TO TAME YOUR DRAGONS SO YOU CAN FEEL
          HAPPIER, CALMER, AND MORE IN CONTROL OF YOUR EMOTIONS.
        </Flex>
      </Container>
      <h2
        sx={{
          textTransform: "uppercase",
          color: "green",
          fontSize: 40,
          textAlign: "center",
          fontWeight: 600,
          mb: 40,
        }}
      >
        where should we send your results?
      </h2>
      <Flex sx={{ alignItems: "center", mb: 50, flexDirection: "column" }}>
        <form onSubmit={handleSubmit(onSubmit)} sx={{ ...styles }}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            ref={register({ required: true })}
          />
          {errors.firstName && "First Name is required"}

          <input
            type="email"
            name="email"
            placeholder="Email"
            ref={register({ required: true })}
          />
          {errors.email && "Email is Required"}
          <Flex sx={{ justifyContent: "center" }}>
            <input type="submit" value="send my results" />
          </Flex>
        </form>
        <Container sx={{ maxWidth: 500, textAlign: "center", fontSize: 14 }}>
          <em>
            *By opt-ing in, you are choosing to subscribe to our BrainMD and/or
            Amen Clinics email newsletters. You may opt out or change your
            preferences at any time. If you unsubscribe, you will still receive
            your results. Your results and information will never be distributed
            or shared.{" "}
          </em>
        </Container>
        {resultErrors && (
          <div
            sx={{
              variant: "text.error",
            }}
          >
            You cannot submit several times with the same email
          </div>
        )}
      </Flex>
    </>
  )
}

const styles = {
  input: {
    display: "block",
  },
  'input[type="text"], input[type="email"]': {
    border: "2px solid",
    borderColor: "borderColor",
    height: 51,
    width: 340,
    mb: 20,
    px: 20,
  },
  'input[type="submit"]': {
    variant: "buttons.primary",
    bg: "transparent",
    // width: 233,
    fontSize: 18,
    "&:hover": { color: "#fff", bg: "primary" },
  },
}
