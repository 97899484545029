/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ls from "local-storage"
import { navigate } from "gatsby"
import { window } from "browser-monads"
import { radioStyles } from "./styles"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/globalContextProvider"

export const QuestionSet = ({ dragons }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  // Array of [{question-1: ""}, {question-2: ""} ]
  /* This is a relation object for parsing question keys into text keys,
  we cannot use the names of the questions as names in the fields do to useForm behavior.
  It is complemented with `parsePageQuestionsRel`
  */
  const [pageQuestionsRel, setPageQuestionsRel] = useState([])
  const [allQuestionsError, setAllQuestionsError] = useState(false)

  const { currentQuestions, answers } = state

  const {
    dragonFields: { dragonQuestions, limit },
  } = dragons[currentQuestions]

  const { register, handleSubmit, errors, reset } = useForm()

  const isDragonDetected = answers =>
    Object.values(answers).filter(item => item === "true").length >= limit

  /**
   * Adds a new answer to the total answers in context or updates an existing one!
   * @param {object} data - The data for this answer ({"question-0-1": "true", "question-1-1": "false"}) ...
   */
  const setAnswers = async data => {
    let _answers = [...answers]
    const newAnswer = {
      title: dragons[currentQuestions].title,
      answers: data,
      detected: isDragonDetected(data) && true,
    }

    //we go back to prev answered question
    if (answers.length > currentQuestions) {
      _answers[currentQuestions] = newAnswer
    } else {
      //new answer
      _answers = [...answers, newAnswer]
    }

    await dispatch({
      type: "SET_ANSWERS",
      answers: _answers,
    })
  }

  useEffect(() => {
    ls("localAnswers", answers)
  }, [answers])

  // New "page" clicked! ,new question, parse the questions to have an index
  useEffect(() => {
    const pageQuestionsRel = dragonQuestions.reduce((acc, question, i) => {
      acc[`question-${i}`] = question.questions
      return acc
    }, {})
    setPageQuestionsRel(pageQuestionsRel)
  }, [currentQuestions])

  const next = () => {
    if (currentQuestions + 1 < dragons.length) {
      dispatch({
        type: "SET_CURRENT_QUESTIONS",
        currentQuestions: currentQuestions + 1,
      })
      window.scrollTo(0, 0)
    } else {
      navigate("/results")
    }
  }

  const back = () => {
    if (currentQuestions > 0) {
      dispatch({
        type: "SET_CURRENT_QUESTIONS",
        currentQuestions: currentQuestions - 1,
      })
      window.scrollTo(0, 0)
    } else {
      dispatch({
        type: "SET_CURRENT_QUESTIONS",
        currentQuestions: 0,
      })
    }
  }

  /**
   * Parses the object for the form {question-1: '', question-2: '', ...} into the end
   * results: {'text of question 1': '', 'text of question 2': '', ...}
   *
   * @param {object} data - An object like {question-1: '', question-2: '', ...}
   * @returns {object} - An object like {'text of question 1': '', 'text of question 2': '', ...}
   */
  const parsePageQuestionsRel = data => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[pageQuestionsRel[key]] = escapeSpecialChars(value)
      return acc
    }, {})
  }

  const escapeSpecialChars = str => str.replace(/(\r\n|\n|\r)/gm, "")

  const validate = data =>
    Object.values(data).every(item => item !== null && item !== "")

  const onSubmit = data => {
    if (validate(data)) {
      data = parsePageQuestionsRel(data)
      setAnswers(data)
      setAllQuestionsError(false)
      reset()
      next()
    } else {
      setAllQuestionsError(true)
    }
  }

  const handleBack = e => {
    e.preventDefault()
    reset()
    back()
  }

  const showBackButton = () =>
    currentQuestions > 0 && currentQuestions < dragons.length

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          ".question": {
            "&:nth-child(odd)": {
              bg: "lightGrey",
            },
          },
        }}
      >
        {dragonQuestions?.map((item, i) => {
          const question = item.questions
          const name = `question-${i}`

          return (
            <Flex
              className="question"
              key={i}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: ["wrap", "wrap", "nowrap"],
                pl: 35,
                pr: 50,
                py: 15,
              }}
            >
              <div sx={{ maxWidth: [350, 400, 630], mb: [15, 15, 0] }}>
                {question}
              </div>

              <Flex sx={{ ...radioStyles, position: "relative" }}>
                <div className="radioContainer">
                  <input
                    type="radio"
                    id={`yes-${name}`}
                    key={`yes-${name}`}
                    name={name}
                    value={true}
                    ref={register}
                    defaultChecked={
                      answers[currentQuestions]?.answers[
                        pageQuestionsRel[name]
                      ] === "true"
                    }
                    // required
                  />

                  <label htmlFor="yes">YES</label>
                </div>
                <div className="radioContainer">
                  <input
                    type="radio"
                    id={`no-${name}`}
                    key={`no-${name}`}
                    name={name}
                    value={false}
                    ref={register}
                    defaultChecked={
                      answers[currentQuestions]?.answers[
                        pageQuestionsRel[name]
                      ] === "false"
                    }
                    // required
                  />
                  <label htmlFor="no">NO</label>
                </div>
              </Flex>
            </Flex>
          )
        })}
        <Flex
          sx={{
            justifyContent: ["center", "space-between"],
            px: [0, 0, 60],
            pb: [40, 0],
          }}
        >
          <button
            onClick={handleBack}
            sx={{
              ...buttonStyle,
              display: [showBackButton() ? "block" : "none", "block"],
              visibility: showBackButton() ? "visible" : "hidden",
            }}
          >
            Back
          </button>

          <input
            type="submit"
            value="next"
            sx={{
              ...buttonStyle,
            }}
          />
        </Flex>
      </form>

      {Object.values(errors)?.length > 0 ||
        (allQuestionsError && (
          <Flex sx={{ justifyContent: "center", mb: 40 }}>
            <div sx={{ variant: "text.error" }}>
              You must answer all questions
            </div>
          </Flex>
        ))}
    </div>
  )
}

const buttonStyle = {
  width: [125, 215],
  mx: 10,
  height: [40, 51],
  border: "2px solid",
  borderColor: "green",
  fontSize: 18,
  fontFamily: "heading",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 14,
  mt: 25,
  variant: "transitions.s",
  bg: "transparent",
  cursor: "pointer",

  "&:hover": {
    bg: "green",
    color: "#fff",
  },
}
