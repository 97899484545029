import config from "../../config"

export const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(config.wordPressUrl, ``)
}

export const escapeSpecialChars = str => str.replace(/(\r\n|\n|\r)/gm, "")

export const removeMarks = str => {
  str = str.trim()
  if (str.charAt(0) === '"' || str.charAt(0) === "“" || str.charAt(0) === "”") {
    str = str.substring(1)
    if (
      str.charAt(str.length - 1) === '"' ||
      str.charAt(str.length - 1) === "“" ||
      str.charAt(str.length - 1) === "”"
    ) {
      str = str.substring(0, str.length - 1)
    }
  }

  return str
}

export const createEmailInput = (
  mailData,
  resultsTitlesArray,
  url,
  id,
  resultId
) => {
  const { email } = mailData
  let _titleHTML = `<ul>`
  resultsTitlesArray.forEach(
    title =>
      (_titleHTML += `<li style="font-family: 'Open Sans', sans-serif; text-transform: uppercase">${title}</li>`)
  )
  _titleHTML += `</ul>`
  return {
    clientMutationId: id,
    to: email,
    from: "Daniel G. Amen, MD <support@amenuniversity.com>",
    subject: "Your Results Are IN!",
    body: `

    <p style="font-family: 'Open Sans', sans-serif">
    Thank you for taking my Dragons from the Past Quiz. Based on your answers, the
    following Dragons have been detected:
  </p>
  ${_titleHTML}
  <p style="font-family: 'Open Sans', sans-serif">
    Dragons from the Past are issues from memories and events in your life that
    continue to breathe fire on your emotional brain. Unless you recognize and
    tame your inner Dragons, they can steal your happiness, damage your
    relationships, steal your health, rob your ability to cope with stress, and
    limit your destiny. When Dragons control your brain, they can contribute to
    anxiety, depression, addictions, and other mental health conditions. And your
    entire life suffers.
  </p>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">
    The good news is that you have an opportunity to change that. Once you become
    aware of and tame your Dragons, you can break bad habits, shut down
    self-defeating thoughts, shore up your capacity to cope with uncertainty, and
    live your best life.
  </p>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">
    Getting to know your Dragons is the first step in learning to tame them so you
    can gain control of your emotions and optimize your life.
  </p>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">
    Click below to download your FREE report to discover more about the origins of
    your Dragons, what triggers them, and the reactions they cause.
  </p>
  <br/>
  <div style="display: flex; justify-content: center">
    <a
      href="${url}/results/${resultId}"
      target="_blank"
      style="
        display: block;
        background: #ecdd2e;
        text-align: center;
        text-transform: uppercase;
        font-size: 30px;
        padding: 20px 80px;
        max-width: 300px;
        font-family: 'Open Sans', sans-serif;
        text-decoration: none;
        font-size: 16px;
        color: black;
        font-weight: 600;
        margin: 30px 10px;
      "
    >
      GET MY FREE DRAGONS REPORT</a
    >
  </div>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">
    Congratulations! You’re on your way to becoming a Dragon Tamer!
  </p>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">To your brain health,</p>
  <br/>
  <p style="font-family: 'Open Sans', sans-serif">Daniel G. Amen, MD</p>
    `,
  }
}
